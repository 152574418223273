import React, { useState, useEffect } from 'react'
import ClientProvider from '../ClientProvider';
import { saveClient } from './ButtonSaveClient'

export default function ButtonRunCrawler() {
  const wsProvider = React.useContext(ClientProvider);
  const crawlerKey = wsProvider.getCrawlerSelected()

  const [waitingForBatchJobsDone, setWaitingForBatchJobsDone] = useState([])

  const actionHandler = (engine) => {
    saveClient(wsProvider, () => {
      console.log("Going to Run Crawler")

      //Change Tab
      /*
      const [workspaceTabs, setWorkspaceTabs] = wsProvider.useStateVarGlobal("workspaceTabs", 0)
      if (wsProvider.CrawlerTree.getItems() && wsProvider.CrawlerTree.getItems().length > 50) {
        setWorkspaceTabs(1)
      }
      */

      // Clear items
      wsProvider.CrawlerTree.clearItems()

      // Clear stats     
      if (wsProvider.clientConfig[0]["settings"]["autoload_stats"] === false) {
        wsProvider.CrawlerTree.clearStats()
      }

      const crawlItemsMax = wsProvider.CrawlerTree.getSetting("crawl_max_items")
      const save_xlsx = wsProvider.CrawlerTree.getSetting("save_xlsx")
      if (engine !== "thread") {
        let options = {
          client: wsProvider.clientSelected,
          crawlerSelected: crawlerKey,
          engine: engine
        }

        wsProvider.ws.waitForSocketAndSend({
          action: "runCrawlerCompleteBatch",
          options: options
        }, (data) => {
          setWaitingForBatchJobsDone(x => [...x, data["batchJobId"]])

          // SEND INIT STATS TO WSPROVIDER
          const batchJobNameId = data["batchJobName"] + data["batchJobId"]
          wsProvider.setBatchJobsStats(x => {
            const newData = { ...x }
            newData[batchJobNameId] = {
              batchJobName: data["batchJobName"],
              batchJobId: data["batchJobId"],
              stats: data["stats"]
            }
            return newData
          })
        })
      } else {
        wsProvider.ws.waitForSocketAndSend({
          action: "runCrawlerComplete",
          options: {
            client: wsProvider.clientSelected,
            crawlerSelected: crawlerKey,
            crawlItemsMax: crawlItemsMax > 0 ? crawlItemsMax : '',
            save_xlsx: save_xlsx ? true : false,
          }
        })
      }
    })
  }


  const itemsMaxHandler = (e) => {
    wsProvider.CrawlerTree.changeAttr("settings", {
      ...wsProvider.CrawlerTree.getAttr("settings"),
      crawl_max_items: e.target.value ? e.target.value : ''
    })
  }

  const handleBatchJobUpdate = (data) => {

    console.log(data)
    if ((data.batchJobName === "CrawlerRun" || data.batchJobName === "CrawlerRunParallel") && waitingForBatchJobsDone.includes(data["batchJobId"]) && data.level === "batchJob" && data.stats.status === "SUCCEEDED") {

      if (wsProvider.clientConfig[0]["settings"]["autoload_items"] !== false) {
        wsProvider.CrawlerTree.loadItems()
      }

      if (wsProvider.clientConfig[0]["settings"]["autoload_stats"] !== false) {
        wsProvider.CommandsTree.loadStatsCommand(wsProvider.CommandsTree.getAttr("key"))
      }

    }
  }

  useEffect(() => {
    // Register Callback
    // Return is the cleanup function
    return wsProvider.registerBatchJobUpdateHandler("buttonRunCrawler", handleBatchJobUpdate)
  }, [JSON.stringify(waitingForBatchJobsDone)])

  return (
    <>
      <div className="col-1">
        <div className="">
          <span className={wsProvider.checkIsLoading() ? "spinner-border spinner-border-sm text-light" : "notLoading"} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
          <input
            className="form-control form-control-sm itemsMaxInput"
            value={wsProvider.CrawlerTree.getSetting("crawl_max_items")}
            onChange={itemsMaxHandler}
          />
        </div>
      </div>
      <div className="dropdown col-1">
        <div className="d-grid gap-2">

          <button
            className="btn btn-primary btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={wsProvider.clientConfig.length > 0 && wsProvider.connectedStatus && !wsProvider.checkIsLoading() ? '' : 'disabled'}
          >
            Run (All)
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a className="dropdown-item" href="#" onClick={() => actionHandler("awslambda")}>AWS Lambda</a></li>
            <li><a className="dropdown-item" href="#" onClick={() => actionHandler("awsbatch")}>AWS Batch</a></li>
            <li><a className="dropdown-item" href="#" onClick={() => actionHandler("os")}>OS</a></li>
            <li><a className="dropdown-item" href="#" onClick={() => actionHandler("thread")}>Websocket-Thread</a></li>
          </ul>
        </div>
      </div>
    </>
  )
}

