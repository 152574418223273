/* eslint-disable no-console, react/no-access-state-in-setstate */
import React from 'react';
import Tree from 'rc-tree';
import './index.css';
import ClientProvider from '../../Various/ClientProvider';

import CrawlerPicker from '../../Various/CrawlerPicker';
import CompWrapper from '../CompWrapper';


function allowDrop({ dropNode, dropPosition }) {
  if (!dropNode.children) {
    if (dropPosition === 0) return false;
  }
  if (dropNode.key === 'root')
    return false
  return true;
}

function makeid() {
  let length = 20
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
  }
  return result.join('');
}

class CrawlerTree extends React.Component {

  constructor(props, context) {
    super(props, context)
    context.setCrawlerTree(this)
    this.items_loading = false

  }

  static contextType = ClientProvider

  state = {
    autoExpandParent: true,
    expandedKeys: ['root']
  };

  onSelect = selectedKeys => {
    if (selectedKeys[0] && selectedKeys[0] !== 'root') {

      const [crawlerSelected, setCrawlerSelected] = this.context.useStateVarGlobal("crawlerSelected")
      setCrawlerSelected(selectedKeys[0])
    }
  };

  onDragStart = info => {
    console.log('start', info);
  };

  onDragEnter = () => {
    console.log('enter');
  };

  onDrop = info => {
    console.log('drop', info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...this.context.clientConfig];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (dropPosition === 0) {
      // Drop on the content
      loop(data, dropKey, item => {
        // eslint-disable-next-line no-param-reassign
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      // Drop on the gap (insert before or insert after)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.context.setClientConfig(data)
  };

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: true,
    });
  };

  addEmptyCrawler = () => {
    const newCrawlers = [{
      title: '',
      key: makeid(),
      commands: []
    }]
    this.addCrawler(newCrawlers, true)
  }

  addCrawler = (newCrawlers, focus) => {
    var root = { ...this.context.clientConfig[0] }


    newCrawlers = newCrawlers.map(x => {
      if (this.context.clientConfig[0].children.find(element => element.key === x.key))
        return { ...x, "key": makeid() }
      else
        return x
    })
    //Check keys

    root["children"] = [...this.context.clientConfig[0].children, ...newCrawlers]

    this.context.setClientConfig([root])
    if (focus) {

      const [crawlerSelected, setCrawlerSelected] = this.context.useStateVarGlobal("crawlerSelected")
      setCrawlerSelected(newCrawlers[newCrawlers.length - 1]["key"])

      if (this.nameInput) {
        this.nameInput.select()
        this.nameInput.focus()
      }
    }
  }

  addCrawlerFromImport = (crawlerConfig) => {
    var root = { ...this.context.clientConfig[0] }
    root["children"] = [...this.context.clientConfig[0].children, crawlerConfig]
    this.context.setClientConfig([root])
  }

  titleChangeHandler = (e) => this.changeAttr("title", e.target.value)

  changeAttr = (key, value) => {
    var root = { ...this.context.clientConfig[0] }
    const childrenKey = this.getCrawlerSelectedKey()
    root.children[childrenKey][key] = value
    this.context.setClientConfig([root])
  }

  getAttr = (key) => {
    if (!this.context.clientConfig || !this.context.clientConfig[0])
      return null
    let toRead = this.context.clientConfig[0].children[this.getCrawlerSelectedKey()]
    return toRead ? toRead[key] : null
  }

  getSetting = (key) => {
    var settings = this.getAttr("settings")
    settings = settings ? settings : {}
    return key in settings ? settings[key] : undefined
  }

  getFields = (showDismissedFields = true) => {
    // Concat and Make Unique
    return [...new Set(this.getCustomFields().concat(showDismissedFields === true ? Object.keys(this.context.baseFields) : Object.keys(this.context.baseFields).filter(key => this.context.baseFields[key]["dismiss"] !== true)))]
  }

  getCustomFields = () => {
    let fields = this.getSetting("custom_fields")
    return fields ? fields.map(x => x.field_name) : []
  }

  getCrawlerSelectedKey = () => {
    return this.context.clientConfig[0].children.findIndex(x => x.key === this.context.getCrawlerSelected())
  }

  getChildByKey = key => {
    if (!this.context.clientConfig[0] || this.context.clientConfig[0].length === 0)
      return false
    return this.context.clientConfig[0].children.find(element => element.key === key)
  }

  deleteCrawler = () => {
    var root = { ...this.context.clientConfig[0] }

    const childrenKey = this.getCrawlerSelectedKey()
    const newSelectedKey = root.children.length - 1 === childrenKey ? childrenKey - 1 : childrenKey

    //Update Client Config
    root["children"] = [...this.context.clientConfig[0].children].filter(x => x.key !== this.context.getCrawlerSelected())
    this.context.setClientConfig([root])

    //Update Selected Value
    if (newSelectedKey >= 0) {
      const [crawlerSelected, setCrawlerSelected] = this.context.useStateVarGlobal("crawlerSelected")
      setCrawlerSelected(root["children"][newSelectedKey].key)
    }

  }
  runClientBatchJob = () => {
    this.context.ws.waitForSocketAndSend({
      action: "batchJobInit",
      options: {
        "batchJobName": "edufind.ClientsRunEdufind",
        "batchJobOptions": {
          "engine": "awsbatch",
          "projectPathSelector": {
            "project": this.context.selectedProjectId,
            "selectionType": "single",
            "selectionValue": this.context.clientSelected,
            "maxClients": 0
          },
          "runCrawlerCommandSet": true
        }
      }
    }, (response) => {

    })
  }
  runClientBatchJobCollectToValidate = () => {
    this.context.ws.waitForSocketAndSend({
      action: "batchJobInit",
      options: {
        "batchJobName": "edufind.ClientsRunEdufind",
        "batchJobOptions": {
          "engine": "awslambda",
          "projectPathSelector": {
            "project": this.context.selectedProjectId,
            "selectionType": "single",
            "selectionValue": this.context.clientSelected,
            "maxClients": 0
          },
          "runCollectorCommandSet": true,
          "runCrawlerTranslationCommandSet": true,
          "runTopicGroupsAddCommandSet": true,
          "runValidatorCommandSet": true
        }
      }
    }, (response) => {

    })
  }
  runClientBatchJobPublish = () => {
    this.context.ws.waitForSocketAndSend({
      action: "batchJobInit",
      options: {
        "batchJobName": "edufind.ClientsRunEdufind",
        "batchJobOptions": {
          "engine": "awslambda",
          "projectPathSelector": {
            "project": this.context.selectedProjectId,
            "selectionType": "single",
            "selectionValue": this.context.clientSelected,
            "maxClients": 0
          },
          "runMetadataCommandSet": true,
          "runCampaignEngineCommandSet": true,
          "runEduFindEsIndexerCommandSet": true,
          "runCpcEnricherCommandSet": true
        }
      }
    }, (response) => {

    })
  }
  getTitleInputValue = () => {
    if (this.getChildByKey(this.context.getCrawlerSelected()))
      return this.getChildByKey(this.context.getCrawlerSelected()).title
    else
      return ''
  }

  loadItems = () => {
    //Load Items
    let crawlerKey = this.getAttr("key")
    if (!crawlerKey)
      return null

    this.items_loading = true
    this.context.ws.waitForSocketAndSend({
      action: "getItems",
      options: {
        client: this.context.clientSelected,
        crawler: crawlerKey
      }
    }, (response) => {
      this.items_loading = false
      if (response) {
        this.setItems(items2 => response, crawlerKey)
      }
    })
  }

  getItems = (loadItems = false) => {
    let crawlerKey = this.getAttr("key")
    let itemsStoreKey = this.context.clientSelected + "/" + crawlerKey
    if (!crawlerKey)
      return null

    const itemsAll = this.context.itemsAll

    if (itemsAll === undefined || !(itemsStoreKey in itemsAll)) {
      if (loadItems === true) {
        this.loadItems(crawlerKey)
      }
      return null
    }
    var data2 = itemsAll[itemsStoreKey]

    if (!data2)
      return null

    return data2
  }

  setItems = (f, crawlerKey) => {
    crawlerKey = crawlerKey !== undefined ? crawlerKey : this.getAttr("key")
    let itemsStoreKey = this.context.clientSelected + "/" + crawlerKey

    this.context.setItemsAll(itemsAll => {
      let newItemsAll = { ...itemsAll }
      if (!(itemsStoreKey in itemsAll)) {
        itemsAll[itemsStoreKey] = []
      }
      newItemsAll[itemsStoreKey] = f(itemsAll[itemsStoreKey])
      return newItemsAll
    })
  }

  clearItems = () => {
    const crawlerKey = this.getAttr("key")
    let itemsStoreKey = this.context.clientSelected + "/" + crawlerKey

    this.context.setItemsAll(itemsAll => {
      const itemsAllNew = { ...itemsAll }
      delete itemsAllNew[itemsStoreKey]
      return itemsAllNew
    })
  }

  clearStats = () => {
    const crawlerKey = this.getAttr("key")
    this.context.setStats(stats => {
      const newStats = {
        ...stats,
        [this.context.clientSelected]: {
          ...stats[this.context.clientSelected],
        }

      }
      delete newStats[this.context.clientSelected][crawlerKey]
      return newStats
    })

  }

  render() {
    return (
      <CompWrapper
        className="CrawlerTree"
        title={"Crawler"}
        buttonBar={(
          <>
            <CrawlerPicker />
            <button
              onClick={this.addEmptyCrawler}
              className="btn btn-primary btn-sm"
              disabled={(this.context.clientConfig.length > 0) ? "" : "disabled"}
              title='Insert Empty Crawler'

            >
              +
            </button>
            <button
              onClick={this.deleteCrawler}
              className="btn btn-primary btn-sm"
              disabled={(this.getChildByKey(this.context.getCrawlerSelected())) ? "" : "disabled"}
              title='Delete Crawler'
            >
              -
            </button>
            <button
              onClick={this.runClientBatchJob}
              className="btn btn-primary btn-sm"
              title='Run all Crawlers'
            >
              <i className="bi bi-play-circle"></i>
            </button>
            <button
              onClick={this.runClientBatchJobCollectToValidate}
              className="btn btn-primary btn-sm"
              title='Run Collect to Validate'
            >
              <i className="bi bi-check"></i>
            </button>
            <button
              onClick={this.runClientBatchJobPublish}
              className="btn btn-primary btn-sm"
              title='Run After Validate to Publish (End)'
            >
              <i className="bi bi-send"></i>
            </button>
          </>
        )}
      >
        <Tree
          allowDrop={allowDrop}
          expandedKeys={["root"]} //FIXED, this.state.expandedKeys
          selectedKeys={[this.context.getCrawlerSelected()]}
          onExpand={this.onExpand}
          onSelect={this.onSelect}
          autoExpandParent={this.state.autoExpandParent}
          draggable
          onDragStart={this.onDragStart}
          onDragEnter={this.onDragEnter}
          onDrop={this.onDrop}
          treeData={this.context.clientConfig}
        />
        <div className='row'>
          <div className='col-6'>
            <input
              ref={(input) => { this.nameInput = input; }}
              value={this.getTitleInputValue()}
              onChange={(e) => this.titleChangeHandler(e)}
              disabled={(this.getChildByKey(this.context.getCrawlerSelected())) ? "" : "disabled"}
              className="form-control form-control-sm"
            />
          </div>
          <div className='col-6 crawlerKey'>
            {this.context.getCrawlerSelected()}
          </div>
        </div>

      </CompWrapper>
    );
  }
}

export default CrawlerTree;